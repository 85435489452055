import Box, { Content, Section } from 'components/box'
import convertImageMap from 'helpers/convertImageMap'
import Img from 'gatsby-image'
import Layout from 'components/layout'
import PropTypes from 'prop-types'
import React from 'react'
import { graphql } from 'gatsby'
import { Heading, Lead } from 'components/text'
import { Link as GatsbyLink } from 'gatsby'
import { SecondaryButton } from 'components/button'

const Subscribed = ({ data }) => {
  const { page } = data
  const icons = convertImageMap(page.icons)

  return (
    <Layout pageTitle="Subscribed">
      <Section>
        <Content textAlign="center" pb={6} pt={5}>
          <Box size="12rem" mx="auto" mt={-2}>
            <Img {...icons['Subscribed']} />
          </Box>
          <Heading level={1} mt={2}>
            Hooray!
          </Heading>
          <Lead
            color="neutral-500"
            fontWeight="medium"
            measure="normal"
            mb={4}
            mt={1}
            mx="auto"
          >
            Thank you very much for joining the beta list. Spread the word!
          </Lead>
          <SecondaryButton as={GatsbyLink} to="/" size="large" mb={3}>
            Go back home
          </SecondaryButton>
        </Content>
      </Section>
    </Layout>
  )
}

Subscribed.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Subscribed

export const query = graphql`
  query SubscribedQuery {
    page: metaJson {
      icons {
        title
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
